import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import "./GalleryCss.css";

const Modal = ({ children, onClose }) => {
    return (
        <div className="modal show d-block" tabIndex="-1" style={{ backgroundColor: 'rgba(0,0,0,0.7)' }}>
            <div className="modal-dialog modal-xl modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-body position-relative p-4">
                        {/* Close Button */}
                        <button
                            type="button"
                            className="btn-close position-absolute top-0 end-0 m-3 z-3"
                            onClick={onClose}
                            aria-label="Close"
                            style={{ zIndex: 1050 }}
                        ></button>
                        {children}
                    </div>
                </div>
            </div>
        </div>
    );
};

// Gallery Component
const GalleryComponent = ({ images = [] }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [currentIndex, setCurrentIndex] = useState(0);

    const openModal = (index) => {
        setCurrentIndex(index);
        setIsModalOpen(true);
    };

    const closeModal = () => setIsModalOpen(false);

    const handleNext = () => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    };

    const handlePrev = () => {
        setCurrentIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
    };

    const handleThumbnailClick = (index) => {
        setCurrentIndex(index);
    };

    const handleKeyDown = (e) => {
        if (e.key === 'ArrowRight') {
            handleNext();
        } else if (e.key === 'ArrowLeft') {
            handlePrev();
        } else if (e.key === 'Escape') {
            closeModal();
        }
    };

    useEffect(() => {
        if (isModalOpen) {
            window.addEventListener('keydown', handleKeyDown);
        } else {
            window.removeEventListener('keydown', handleKeyDown);
        }

        // Cleanup the event listener on unmount
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [isModalOpen]);

    return (
        <div className="row g-4">
            {images.map((img, index) => (
                <div
                    key={index}
                    className="col-6 col-md-3"
                    onClick={() => openModal(index)}
                >
                    <div className="ratio ratio-1x1">
                        <img
                            src={img}
                            alt={`Thumbnail ${index + 1}`}
                            className="w-100 h-100 object-fit-cover rounded hover-scale"
                            style={{
                                cursor: 'pointer',
                                transition: 'transform 0.4s ease-in-out',
                            }}
                        />

                    </div>
                </div>
            ))}

            {/* Display remaining images count if there are more than 6 images */}
            {/* {images.length > 6 && (
                <div
                    className="col-6 col-md-3 mb-3"
                    onClick={() => openModal(6)}
                    style={{ cursor: 'pointer', }}
                >
                    <div className="ratio ratio-1x1">
                        <img
                            src={images[6]}
                            alt={`Thumbnail 7`}
                            className="w-100 h-100 object-fit-cover rounded"
                        />
                        <div className="position-absolute top-0 start-0 w-100 h-100 bg-dark bg-opacity-50 d-flex align-items-center justify-content-center rounded">
                            <span className="text-white fs-5 fw-bold">+{images.length - 6} more</span>
                        </div>
                    </div>
                </div>
            )} */}

            {isModalOpen && (
                <Modal onClose={closeModal}>
                    <div className="d-flex flex-column align-items-center">
                        {/* Large Image Display */}
                        <div className="position-relative mb-4 w-100">
                            <div className="d-flex justify-content-center align-items-center">
                                <button
                                    onClick={handlePrev}
                                    className="btn btn-dark bg-opacity-50 position-absolute start-0 top-50 translate-middle-y z-1"
                                >
                                    &lt;
                                </button>

                                <div className="text-center w-100">
                                    <img
                                        src={images[currentIndex]}
                                        alt={`Image ${currentIndex + 1}`}
                                        className="img-fluid"
                                        style={{
                                            maxHeight: '70vh',
                                            width: '100%',
                                            objectFit: 'contain'
                                        }}
                                    />
                                </div>

                                <button
                                    onClick={handleNext}
                                    className="btn btn-dark bg-opacity-50 position-absolute end-0 top-50 translate-middle-y z-1"
                                >
                                    &gt;
                                </button>
                            </div>
                        </div>

                        {/* Thumbnail Gallery */}
                        <div className="d-flex flex-nowrap overflow-auto mt-4 w-100"
                            style={{ scrollbarWidth: 'none', msOverflowStyle: 'none' }}>
                            {images.map((img, idx) => (
                                <div
                                    key={idx}
                                    className="ratio ratio-1x1 me-2"
                                    style={{
                                        width: '80px',
                                        ['@media (max-width: 768px)']: {
                                            width: '60px'
                                        },
                                        ['@media (max-width: 576px)']: {
                                            width: '40px'
                                        },
                                        flexShrink: 0
                                    }}
                                >
                                    <img
                                        src={img}
                                        alt={`Thumbnail ${idx + 1}`}
                                        className={`img-thumbnail ${currentIndex === idx ? 'border border-primary' : ''}`}
                                        style={{
                                            cursor: 'pointer',
                                            objectFit: 'cover',
                                            transform: currentIndex === idx ? 'scale(1.1)' : 'scale(1)',
                                            transition: 'transform 0.2s',
                                            width: '100%',
                                            height: '100%'
                                        }}
                                        onClick={() => handleThumbnailClick(idx)}
                                    />
                                </div>
                            ))}
                        </div>
                    </div>
                </Modal>
            )}
        </div>
    );
};

export default GalleryComponent;
