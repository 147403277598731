import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./NewsSlider.css"; // Custom CSS

const NewsSlider = ({ newslists }) => {
    const sliderSettings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 768, // For smaller screens, show fewer slides
                settings: {
                    slidesToShow: 1,
                },
            },
        ],
    };

    return (
        <div className="col-md-11 text-center">
            <div className="testimonial-slider-container">
                <Slider {...sliderSettings}>
                    {newslists &&
                        newslists.map((data, index) => (
                            <div key={index} className="news-slide">
                                <div
                                    className="card"
                                    style={{
                                        cursor: "pointer",
                                        border: "1px solid #ddd",
                                        borderRadius: "8px",
                                        overflow: "hidden",
                                    }}
                                    onClick={() => window.open(data.link, "_blank")} // Open link on click
                                >
                                    <img
                                        src={data.image}
                                        alt={data.title}
                                        className="card-img-top fixed-image"
                                    />
                                    <div className="card-body">
                                        <h5 className="card-title single-line">
                                            {data.title}
                                        </h5>
                                        <p className="card-text three-lines">
                                            {data.descrption}
                                        </p>
                                        <div
                                            className="card-source text-secondary"
                                            style={{
                                                fontSize: "0.8rem",
                                                marginTop: "10px",
                                                fontWeight: "bold",
                                            }}
                                        >
                                            Source: {data.source}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                </Slider>
            </div>
        </div>
    );
};

export default NewsSlider;
